// Prepends styling for amount fields
.input-group.with-prepend {
  .form-control.form-control.is-valid {
    border-left: none;
  }

  input {
    border-left: none;
  }

  &.is-valid .input-group-text {
    border-left: 3px solid $green-5;
    border-top: 3px solid $green-5;
    border-bottom: 3px solid $green-5;
  }

  & .input-group-prepend:has(+ :focus) .input-group-text {
    border-left: 3px solid $blue-5;
    border-top: 3px solid $blue-5;
    border-bottom: 3px solid $blue-5;
  }

  &.is-invalid .input-group-prepend .input-group-text,
  &.is-invalid .input-group-prepend .input-group-text.focused {
    border-left: 3px solid $alert-red-border;
    border-top: 3px solid $alert-red-border;
    border-bottom: 3px solid $alert-red-border;
  }

  & .input-group-text.focused {
    border-left: 3px solid $grey-5;
    border-top: 3px solid $grey-5;
    border-bottom: 3px solid $grey-5;
  }
}

.input-group-text {
  background: $white;
  border-left: 3px solid $grey-6;
  border-top: 3px solid $grey-6;
  border-bottom: 3px solid $grey-6;
  border-right: none;
  padding-left: 1.5rem;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  padding-right: 1rem;
}