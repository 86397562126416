@import '../variables';

.alert{
	padding: 1.25rem 1.5rem;
	margin: 1.5rem 0;
	display: flex;
	@media screen and (max-width: $break-mobile) {
		padding: 1rem;
	}
	img{
		margin: 0 0 0 1.5rem;
		float: right;
		min-width: 28px;
		@media screen and (max-width: $break-mobile) {
			margin: 0 0 0 1rem;
		}
		&.alert-icon{
			float: left;
			margin: 0 1.5rem 0 0;
			@media screen and (max-width: $break-mobile) {
				margin: 0 1rem 0 0;
			}
		}
	}
	&.alert-success{
		background: $alert-success;
		border-color: $alert-success;
		color: $blue-2;
	}
	&.alert-warning{
		background: $alert-warning;
		border-color: $alert-warning;
		color: $blue-2;
	}
	
	&.alert-info{
		background: $alert-info;
		border-color: $alert-info;
		color: $blue-2;
	}
	p{
		margin-bottom: 0;
		@media screen and (max-width: $break-mobile) {
			display: grid;
		}
	}
}

.alert-danger{
		background: $alert-danger;
		border-color: $alert-danger;
		color: $blue-2;
	}
#alert-success{
	fill: $alert-success-icon;
}
#alert-warning{
	fill: $alert-warning-icon;
}
#alert-danger{
	fill: $alert-danger-icon;
}
#alert-info{
	fill: $alert-info-icon;
}