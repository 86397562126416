@import '../variables';

/*
	NAVBAR
*/
.navbar {
    padding: 2rem;

    @media screen and (max-width: $break-mobile) {
        padding: 1rem 0 0.25rem 0;
    }
}

.navbar-brand {
    background: url(../images/HCMlogo.png) top left no-repeat;
    background-size: 100%;
    width: 153px;
    height: 74px;
    text-indent: -9999px;

    @media screen and (max-width: $break-mobile) {
        margin-right: .5rem;
    }

    & a {
        display: block;
        border-bottom: none;
        height: 100%;

        &:hover {
            border-bottom: none;
        }
    }
}

.navbar-dark,
.navbar-light {
    .navbar-nav {
        .nav-link {
            color: $green-1 !important;

            @media screen and (max-width: $break-mobile) {
                padding-left: 0;
            }
        }
    }
}

.header-links {
    display: inline-flex;

    & .text-link {
        margin-left: 9px;
        white-space: nowrap;
    }

    & .icon-link {
        cursor: pointer;

        @media screen and (max-width: $break-mobile) {
            padding-right: 0 !important;
            padding-left: .5rem !important;
        }
    }
}

.dropdown {
    margin-top: 2px;
}

.header-links {

    .text-link {

        // There is flicker with transparent border on hover
        // But transparent is needed for sizing for other places.
        // e.g. welcome screen links
        border-bottom: none;

        &:hover {
            border-bottom: none;
        }
    }

    .dropdown-menu {
        padding: 1rem;
        padding-top: 0.8rem;
        right: 0;
        min-width: auto;
        background: $green-1;
        border: none;
        left: auto;

        .text-link {
            margin: 0px;
            border-bottom: none;
            color: $white;
            display: block;

            &:hover {
                color: $grey-2;
            }
        }
    }

    .dropdown-toggle::after {
        vertical-align: 0.2em;
    }
}

.navbar-light .navbar-toggler {
    border-color: transparent;
    padding: 0;
}

button.navbar-toggler:focus {
    outline: 3px auto $green-3;
}

@include media-breakpoint-down(sm) {

    /* smaller logo on xs, sm sizes */
    .navbar-brand {
        width: 100px;
        height: 48px;
    }
}

button.text-link {
    background: none;
    outline: none;
}