.was-validated .form-control:invalid,
.form-control.is-invalid,
.form-control.is-invalid:focus,
.was-validated .form-select:invalid,
.form-select.is-invalid,
.form-select.is-invalid:focus{
  border-color: $alert-red-border;
  box-shadow: none;
}
.form-control.is-invalid{
  background:  $white url(../images/svg/invalid-cross.svg);
  background-size: 24px;
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-position: right 17px center;
}
.form-control.is-valid{
  background:  $white url(../images/svg/tick-green.svg);
  background-size: 18px;
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-position: right 22px center;
}
.invalid-feedback{
  color: $alert-red;
}