.modal{
	background: rgba(255, 255, 255, 0.8);
}
.modal-dialog {
    max-width: 880px;
}
.modal-content{
	border: none;
	padding: 2rem;
}
.modal-header{
	border-bottom: none;
}
.modal-header .close{
	background: url(../images/modal-close.gif) center center no-repeat;
	margin: -2rem -2rem -1rem auto;	
}
.modal-footer{
	border-top: none;
	display: inline;
}