.progress {
    border-radius: 0;
    height: 8px;
    @media screen and (max-width: $break-tablet) {
        margin-bottom: 2rem;
    }
}

.progress-bar {
    border-radius: 0;
}