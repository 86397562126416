.container {
    @media screen and (max-width: $break-mobile) {
        padding: 0;
    }
}

.card-body {
    padding: 3rem 2rem;

    @media screen and (max-width: $break-mobile) {
        padding: 2rem 1rem 4rem 1rem;
    }
}

.form-group {
    margin-bottom: 1.5rem
}

.questions {

    img.form-image {
        margin-top: 2.5rem;
    }

    label,
    .label {

        &.with-tooltip {

            display: inline-flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1rem;

            .help-icon-container {
                height: 24px;
                margin-left: 12px;
            }
        }

    }
}




// Uploads page
.custom-file-label {
    color: $blue-3;

    &::after {
        padding-left: 2rem;
        padding-right: 2rem;
        background-color: $grey-2;
    }
}

.custom-file-input {
    cursor: pointer;
}

.custom-file-input:focus~.custom-file-label {
    border-color: $green-1;
    box-shadow: 0 0 0 0.2rem rgba(0, 183, 168, 0.15);
}

.custom-file-input:focus~.custom-file-label::after {
    border-color: $green-1;
}

// Personal details page
.add-field .col-7 {
    padding: 1rem;

    @media screen and (max-width: $break-mobile) {
        padding: 0;
    }
}

// Credit History page
label.doc-upload-file-label {
    font-size: 16px;
    color: $blue-3;
    margin-bottom: 0;
}

.custom-file {
    border: solid 1px $green-1;
    padding: 10px;
    min-height: 50px;

    input {
        float: right;
    }
}