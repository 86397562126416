/*
VARIABLES (should not have any CSS in)
*/
$green-1:#00B7A8;
$green-2:#59D0C6;
$green-3:#BFEDE9;
$green-4:#b2e9e5;
$green-5:#d9f4f2;

$white:#ffffff;
$white-2:#ebedf0;
$black:#000000;

$grey-1:#CACFD9;
$grey-2:#E1E4EA;
$grey-3:#F6F7F9;
$grey-4:#f9fafb;
$grey-5:#e3edfb;
$grey-6:#ebedf1;
$grey-7:#afb7c5;

$blue-1:#002B5D;
$blue-2:#5F789B;
$blue-3:#73829A;
$blue-4:#91a1bb;
$blue-5:#e3edfb;

$alert-success: #e6f8f6;
$alert-success-icon: #00b7a8;
$alert-warning: #fdf3ea;
$alert-warning-icon: #eb812a;
$alert-danger: #fdebee;
$alert-danger-icon: #ee2f4f;
$alert-info: #ecf3fd;
$alert-info-icon: #4186e7;
$alert-red: #ef304f;
$alert-red-border: #fde0e5;

$font-size-base: 1rem;


// take variables from node_modules/bootstrap/scss/_variables.scss
$theme-colors: (
  primary: $green-1,
  secondary: $blue-1,
  success: $green-3,
  info: $blue-2,
  warning: yellow,
  danger: red,
  light: $grey-3,
  dark: $grey-1
);

$border-radius: 2px;
$border-width: 2px;
$breadcrumb-margin-bottom: 0px;

// breakpoint values
$break-mobile: 576px;
$break-tablet: 768px;
$break-desktop: 992px;

// thumb size of slider control in calc
$form-range-thumb-width: 1.8rem;
$form-range-thumb-height: $form-range-thumb-width;

// bottom margin after each form field/section
$form-group-margin-bottom: 1.5rem;

$card-color: $blue-3