@import '../variables';

.custom-radio {

  label {
    padding: .25rem 0 0 1rem;
    margin-bottom: 1rem;
  }

  .form-check-input {
    --bs-border-width: 3px;
    width: 1.5em;
    height: 1.5em;
  }

  .form-check-input:focus {
    box-shadow: 0 0 0 0.25rem $green-5;
  }

  .form-check-input:checked {
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='1.5' fill='%23fff'/%3e%3c/svg%3e");
    background-color: $green-1;
    border-color: $green-1;
  }

  .form-check-label {
    margin-bottom: 0;
  }

  // This is how custom radio worked in bootstrap 4
  //.form-check-label::before,
  //.form-check-label::after {
  //  width: 1.5rem;
  //  height: 1.5rem;
  //  border-radius: 1.5rem;
  //  border: solid 3px $grey-6;
  //  background: $white;
  //}
  //.form-check-input:checked ~ .form-check-label::after{
  //  border: solid 3px $green-1;
  //  background: $green-1;
  //  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23ffffff'/%3E%3C/svg%3E");
  //  background-size: 50%;
  //  background-position: 50% 50%;
  //  background-repeat: no-repeat;
  //}

  .help-icon-container {
    display: inline-block;
    position:relative;
    top: -3px;
    margin-left: 12px;
  }
}
.hypo-inline-radios ~ .invalid-feedback {
  display: block;
}
.hypo-inline-radios.is-invalid {
  .custom-radio .form-check-label::after,
  .custom-radio .form-check-label::before{
    border: solid 3px $alert-red-border;
  }
}
.form-check-input:focus ~ .form-check-label::before,
.form-check-input:checked ~ .form-check-label::after{
  box-shadow: none;
}
