@import 'variables';

/*FONT*/
@import url('https://fonts.googleapis.com/css?family=Niramit:200,300,400,700'); /*ExtraLight - 200, Light - 300, Regular - 400, Bold - 700*/

body{
    font-family: 'Niramit', sans-serif;
	font-weight: 400;
	color: $blue-3;
	font-size: $font-size-base;
}
h1{
    font-size: 45px;
	color: $blue-1;
	font-weight: 300;
	margin-bottom: 2.5rem;
	@media screen and (max-width: $break-mobile) {
        font-size: 36px;
		margin-bottom: 1.5rem;
		margin-top: 1.5rem;
    }
}
h5 {
    font-size: 45px;
	--bs-card-title-color: #{$blue-1};
	font-weight: 300;
	@media screen and (max-width: $break-tablet) {
        font-size: 28px;
    }
}
h1.oos-title{
	font-size: 28px;
    color: $blue-1;
    margin-bottom: 1.5rem;
	font-weight: 400;
	@media screen and (max-width: $break-mobile) {
        font-size: 24px;
    }
}
h2 {
    font-size: 28px;
	color: $blue-1;
	@media screen and (max-width: $break-mobile) {
        font-size: 18px;
    }
}