.application-journey {
    margin-top: 0;
    margin-bottom: 3rem;
}

.dashboard-panel {

    .btn-secondary {
        @media screen and (max-width: $break-mobile) {
            padding: .4rem .9rem .5rem .8rem;
            border-color: $grey-1;
        }
    }

    .btn-primary {
        @media screen and (max-width: $break-mobile) {
            padding: .4rem .9rem .5rem .8rem;
            border-color: $green-1;
        }
    }
}

.dashboard-header {
    text-align: right;
    padding: 3.5rem 0 2rem 0;
    align-items: center;

    @media screen and (max-width: $break-tablet) {
        padding: 2rem 0 1rem 0;
    }

    @media screen and (max-width: $break-mobile) {
        padding: 1rem 0 0.25rem 0;
    }

    .text-link {
        /* display: inline; */
        margin-left: 9px;
    }

}

h5.card-title {
    margin: 2.5rem 0rem 3rem;

    @media screen and (max-width: $break-tablet) {
        margin: 1.5rem 0rem 2rem;
    }

    @media screen and (max-width: $break-mobile) {
        margin: 1rem 0rem 1.5rem;
    }
}

.dashboard {
    -webkit-box-shadow: 0px 0px 70px 0px #eaecf0;
    -moz-box-shadow: 0px 0px 70px 0px #eaecf0;
    box-shadow: 0px 0px 70px 0px #eaecf0;

    &.card-body {
        padding: 1.25rem 0;

        @media screen and (max-width: $break-mobile) {
            padding: 1rem 0;
        }
    }

    .nav-pills {

        border-bottom: solid 1px $grey-2;
        padding: 0 3rem;
        margin-bottom: 1.5em;

        @media screen and (max-width: $break-mobile) {
            padding: 0 1rem;
        }

        li.nav-item {
            margin-bottom: 0;
            margin-right: 3rem;

            @media screen and (max-width: $break-mobile) {
                margin-right: 1.5rem;
            }

            a {
                border-bottom: none;
            }
        }

        .nav-link {
            color: $blue-3;
            font-weight: 700;
            padding: 0rem;

            &.active {
                color: $blue-1;
                background-color: $white;
                border-bottom: solid 3px $green-1;
            }

            &:hover {
                color: $blue-1;
            }

            span {
                display: block;
                font-size: 12px;
                color: $blue-4;
                font-weight: 400;
                margin-top: 5px;
                margin-bottom: 1rem;
            }
        }
    }

    .table {

        // Remove bootstrap line between rows on dashboard screen
        > :not(caption) > * > * {
            border-bottom: none;
        }

        margin-bottom: 0;
        font-weight: 400;
        font-size: 28px;
        line-height: 32px;
        color: $blue-1;

        @media screen and (max-width: $break-tablet) {
            font-size: 24px;
            line-height: 28px;
        }

        @media screen and (max-width: $break-mobile) {
            font-size: 18px;
            line-height: 20px;
        }

        td {
            border-top: 0;

            span {
                opacity: .2;

                .available {
                    opacity: 1;
                }
            }


            &.tablecell_1of3 {
                width: 10%;
                padding: 1rem 1rem 1rem 3rem;

                @media screen and (max-width: $break-mobile) {
                    padding: 1.5rem 1rem 1rem 1rem;
                    width: 15%;
                    float: left;
                }

                .dashboard-tick {
                    text-indent: -9999px;
                    background: url(../images/tick.gif) top center no-repeat;
                    width: 22px;
                    height: 22px;
                    display: block;
                    margin-top: 4px;
                }

                .dashboard-chevron {
                    text-indent: -9999px;
                    background: url(../images/dashboard-chevron.png) top center no-repeat;
                    width: 22px;
                    height: 22px;
                    display: block;
                    margin-top: 4px;
                }
            }

            &.tablecell_2of3 {
                width: 65%;
                padding: 1rem;

                @media screen and (max-width: $break-mobile) {
                    padding: 0;
                }
            }

            &.tablecell_3of3 {
                width: 35%;
                text-align: right;
                padding: 1rem 3rem 1rem 1rem;

                @media screen and (max-width: $break-mobile) {
                    padding: 1rem;
                }

                .btn-secondary {
                    .dashboard-chevron {
                        text-indent: -9999px;
                        background: url(../images/chevron_secondary.png) bottom center no-repeat;
                        width: 5px;
                        height: 16px;
                        display: inline-block;
                        margin-left: 25px;

                        @media screen and (max-width: $break-mobile) {
                            margin-left: 10px;
                        }
                    }
                }

                .btn-primary {
                    .dashboard-chevron {
                        text-indent: -9999px;
                        background: url(../images/chevron.png) bottom center no-repeat;
                        width: 5px;
                        height: 16px;
                        display: inline-block;
                        margin-left: 25px;

                        @media screen and (max-width: $break-mobile) {
                            margin-left: 10px;
                        }
                    }
                }

                span {
                    display: none;

                    &.available {
                        display: block;
                    }
                }
            }
        }

        .complete {
            color: $blue-2;

        }

        span.available {
            opacity: 1;

            span.dashboard-tick,
            span.dashboard-chevron,
            .btn span {
                opacity: 1;
            }

            .btn span {
                display: inline;
            }

        }

    }

    .dashboard-internal {
        padding: 1rem 4rem;

        @media screen and (max-width: $break-mobile) {
            padding: 1rem 1.5rem;
        }
    }
}

.journey-mobile-menu-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.journey-mobile-menu-contents {
    position: fixed;
    top: 0;
    left: 0;
    right: 30px;
    max-width: 50%;
    bottom: 0;
    background: white;

    @media screen and (max-width: $break-mobile) {
        max-width: 100%;
    }
}

.journey-mobile-menu-close-container {
    padding: 32px;

    @media screen and (max-width: $break-mobile) {
        padding: 20px 20px 0;
    }
}

.journey-mobile-menu-button {
    span {
        margin-right: 16px;

        @media screen and (max-width: $break-mobile) {
            margin-right: 10px;
        }
    }

    .material-icon {
        color: #6c757d
    }
}