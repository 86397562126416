.document-upload{

    &.is-invalid {
        color: $danger;
    }

    .upload-row {

        padding: 1rem 0;
        border-bottom: $grey-2 solid 1px;
        margin: 0;
        -moz-white-space: pre-line;
        -o-white-space: pre-line;
        white-space: pre-line;

        .document-icon {

            border-bottom: none;
            &::before {
                @include material-icon-font;

                content: 'description';
                position: relative;
                top: 2px; left: 0;
            }

            &:empty {
                display: none;
             }
        }

        .document-title {
            margin-bottom: 12px;
        }

        .document-filename{

            font-weight: bold;

            @media screen and (max-width: $break-mobile) {
                max-width: 90%;
                display: inline-block;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }

        .doc-upload-file-input {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
        }
        .doc-upload-file-input + label {
            display: inline-block;
            margin-bottom: 0;
            float: right;
            span{
                background: url(../images/svg/plus.svg) left center no-repeat;
                background-size: 50px;
                padding-left: 35px;
                display: block; 
                text-decoration: underline;
                cursor: pointer;
                &:hover{
                    text-decoration: none;
                }
            }
        }
        .doc-upload-file-input:focus + label {
            outline: 2px solid $green-5;
            outline: -webkit-focus-ring-color $green-5 2px;
        }
        
        

        .btn-view {
            border: none;
            display: inline-block;
            margin-right: 12px;
            &::before {
                @include material-icon-font;

                vertical-align: top;
                content: 'search';
                color: $green-1;
            }
        }

        .btn-remove {
            cursor: pointer;
            &::before {
                @include material-icon-font;

                vertical-align: top;
                content: 'delete';
                color: $green-1;
            }
        }


    }

    .invalid-feedback {
        display: block;
    }
}
.questions{
    .document-upload{
        margin: 0;
        -webkit-box-shadow: none;
  	    -moz-box-shadow: none;  
        box-shadow: none; 
    }
}
