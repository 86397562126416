.testimonials {

    background-color: #f2f2f2;  

    .testimonials-inner {
        width: 100%;
        max-width: 1380px;
        margin: 0 auto;
        position: relative;
    }

    .testimonial-item {
        width: 100%;
        max-width: 1380px;
        text-align: center;
        padding: 3rem;
    }

    .item-text {
        font-style: italic;
    }

    .item-name,
    .item-source {
        color: $green-1;
    }

    .item-source {
        margin-bottom: 12px;
    }

    .star {
        display: inline-block;
        height: 24px;
        width: 24px;
        background-size: 24px;
        background: url(../images/icons/star-24px.svg) no-repeat;
    }

    .swiper-pagination-bullet-active {
        background: $green-1;
    }


    
	@media screen and (max-width: $break-mobile) {
        .swiper-button-prev, 
        .swiper-button-next {
            display: none;
        }

        .testimonial-item {
            padding: 1.5rem;
            max-width: $break-mobile;
        }
	}

}