@import '../variables';

.tooltip-right{
	float: right;
	img{
		margin-top: 1rem;
	}
}
.__react_component_tooltip{
	width: 250px;
	padding: 0.75rem 1.25rem;
	&.type-dark {
		color: $blue-3;
		background-color: $grey-3;
		&.place-left:after {
			border-left-color: $grey-3;
		}
		&.place-right:after {
			border-right-color: $grey-3;
		}
		&.place-bottom:after {
			border-bottom-color: $grey-3;
		}
		&.place-top:after {
			border-top-color: $grey-3;
		}
	}
	&.show{
		opacity: 1;
	}
}
