.material-icon {
  @include material-icon-font;

  vertical-align: bottom;
  cursor: pointer;
}

.material-icon-large {
  line-height: 44px;
  font-size: 44px;
}