.welcome-sidebar {
    margin-top: 6rem;
	padding-left: 3rem;


	& ul {
		padding-left: 0;
	}

	& li {
		padding-left: 33px;
		list-style: none;
		color: $blue-1;

		&.mins {
			background: url(../images/icons/welcome_sidebar_mins.gif) center left no-repeat;
		}
		&.open {
			background: url(../images/icons/welcome_sidebar_24.gif) center left no-repeat;
		}
		&.credit-card {
			background: url(../images/icons/welcome_sidebar_cc.gif) center left no-repeat;
		}
	}
}

button.icon {
    margin-right: 25px;
}

.no-right-padding {
    padding-right: 0px;
}

.welcome-image {
    overflow: hidden;
    @media screen and (max-width: $break-tablet) {
        display: none;
    }
}

.welcome-page {
    .col-3 {
        @media screen and (max-width: $break-tablet) {
            flex: 0 0 30%;
            max-width: 30%;
        }
        @media screen and (max-width: $break-mobile) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .col-5 {
        @media screen and (max-width: $break-tablet) {
            flex: 0 0 70%;
            max-width: 70%;
        }
        @media screen and (max-width: $break-mobile) {
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: 1rem;
        }
    }
    .btn {
        margin-bottom: 1rem;
    }
}

.welcome-loading-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
}
