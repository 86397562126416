/*
AFFORDABILTY CALCULATOR
*/

/** use bootstrap mixins without actually including entire bootstrap */
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

@import '../variables';

.affordability-calc-estimate,
.affordability-calc-ltv,
.affordability-calc-monthly,
.affordability-calc-stamp-duty {
  margin-right: 32px;
}

div.affordability-calc-estimate {
  background-color: #e9ecef;

  padding-bottom: 50px;
  padding-top: 4rem;
  padding-right: 2rem;
  padding-left: 2rem;

  border-radius: 0.3rem;

  p.lead {
    /* Fix homepage-induced p.lead margin */
    margin-top: 0px;
  }

  h1.display-3 {
    margin:0;

    font-size:3rem;

    @include media-breakpoint-down(sm) {
      font-size:10vw;
    }
  }
}

.range-slider-right {
  .input-group-prepend {
    max-width:40%;
  }
}

.custom-range {
  &::-webkit-slider-thumb {
    border:0.4rem solid white;
  }
}

#affordability-calc .form-group {
  margin-bottom:0.5rem;
}

/** as bootstrap is not loaded for wp engine override defaults from wp engine's bootstrap */
#affordability-calc .custom-range {
  height:40px;
  margin-top:-8px;

  &::-ms-track {
    margin-top:8px;
    margin-bottom:8px;
  }

  &::-webkit-slider-thumb {
    width: $form-range-thumb-width;
    height: $form-range-thumb-height;
    margin-top: calc(($form-range-track-height - $form-range-thumb-height) / 2); // Webkit specific
  }

  &::-moz-range-thumb {
    width: $form-range-thumb-width;
    height: $form-range-thumb-height;
  }

  &::-ms-thumb {
    width:0.8rem;
    height:0.8rem;
    margin-top:0 !important;
  }
}

@include media-breakpoint-down(lg) {
  @supports (position: sticky) {
    .sticky-top-md {
      position: sticky;
      top: 0;
      z-index: 1020;
      background-color: rgba(255, 255, 255, 0.8);
    }
  }
}

div.sticky-top-md {
  div.affordability-calc-estimate {
    padding-top:10px;
    padding-bottom:10px;
    padding-right:10px;

    margin-right: 0px;

    p.lead {
      margin-bottom:0;
    }
  }

  .help-icon-container {
    position: absolute;
    right: 30px;
    bottom: 24px;
  }
}

.covid-discount {
  color:#E22323;
  display: contents
}