@font-face {
  font-family: 'Material Icons Round';
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/Material-Icons-Round.eot); /* For IE6-8 */
  src: local('Material-Icons-Round'),
  url(./fonts/Material-Icons-Round.woff2) format('woff2'),
  url(./fonts/Material-Icons-Round.woff) format('woff'),
  url(./fonts/Material-Icons-Round.svg) format('svg'),
  url(./fonts/Material-Icons-Round.ttf) format('truetype');
}

@mixin material-icon-font {
  font-family: 'Material Icons Round';

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}