@import '../variables';
/*
FIELD DATE
*/

.hypo-date-row ~ .invalid-feedback {
    display: block;
}

.hypo-date-row {
    width: 100%;
    margin-left:0;
    margin-right:0;
    display: flex;

    @media screen and (max-width: $break-mobile) {
        display: block
    }

    .hypo-date-item {
        position: relative;
        width: 100%;

        input{
            text-align: center;
        }
        &.hypo-date-day, &.hypo-date-month {
            flex: 0 0 30%;
            max-width: 30%;
            padding-right: 5px;
        }
        &.hypo-date-month {
            padding-left: 5px;
        }
        &.hypo-date-year {
            flex: 0 0 40%;
            max-width: 40%;
            padding-left: 5px;
            padding-right: 0;
        }
        &.hypo-date-item {
            @media screen and (max-width: $break-mobile) {
                flex: 0 0 100%;
                max-width: 100%;
                padding-left: 0;
                padding-right: 0;
            }
        }
        input {
            @media screen and (max-width: $break-desktop) {
                margin-bottom: 1rem;
            }
        }
    }
}

.date-boxes .hypo-date-row{
    width: 60%;
    @media screen and (max-width: $break-desktop) {
        width: 100%;
    }
}

/* is it really used?
 */
.add-field .hypo-date-row{
    width: 100%;
    @media screen and (max-width: $break-desktop) {
        width: 100%;
    }
  /* .form-control.is-valid{
    background-position: right 16px center;
      @media screen and (max-width: $break-desktop) {
        background-position: right 10px center;
      } 
      @media screen and (max-width: $break-tablet) {
        background-position: right 10px center;
      } 
      @media screen and (max-width: $break-mobile) {
        background-position: right 22px center;
      } 
  }
  .form-control.is-invalid{
    background-position: right 10px center;
      @media screen and (max-width: $break-desktop) {
        background-position: right 4px center;
      } 
      @media screen and (max-width: $break-tablet) {
        background-position: right 10px center;
      } 
      @media screen and (max-width: $break-mobile) {
        background-position: right 22px center;
      }
  } */
    .hypo-date-item {
          @media screen and (max-width: $break-desktop) {
              flex: 0 0 100%;
              max-width: 100%;
          }
    }
}