// Form main elements related



.form-control{
  color: $blue-3;
  border: 3px solid $grey-6;
  margin-bottom: 0.5rem;
  height: auto;
  padding: 0.6rem 1rem;
  overflow: hidden;
}

.form-select {
  margin-bottom: 0.5rem;
}

/* remove red border in Firefox */
input {
  box-shadow:none;
}

.form-control.form-control.is-valid{
  border: 3px solid $green-5;
}
.form-control:focus, .form-control.is-valid:focus, .form-select:focus{
  border-color: $blue-5;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;  
  box-shadow: none;
  outline: none;
  color: $blue-3;
}
input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill{
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  -moz-box-shadow: 0 0 0px 1000px white inset;
  box-shadow: 0 0 0px 1000px white inset;
  background-color: $white !important;
  background-image: none !important;
  color: $blue-5 !important;
}


/* Hide HTML5 Up and Down arrows. */
input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.is-invalid ~.invalid-feedback {
  display: block;
}

.input-group.is-invalid input {
  border-color: $alert-red-border;
}

.row-group-invalid:not(.editing) {

  color: map-get($theme-colors, danger);

  & button.group-addable-delete span, 
  & button.group-addable-delete::before,
  & button.group-addable-edit span, 
  & button.group-addable-edit::before {
    color: map-get($theme-colors, danger);
  }

  & button.group-addable-delete span:hover,
  & button.group-addable-edit span:hover {
    border-bottom: 1px solid map-get($theme-colors, danger) !important;
  }
}

/* Ideal postal codes autocomplete not to overlap */
.idpc_ul {
  z-index:2000 !important;
}