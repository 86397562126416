.mortgage-documents{
    /* margin: 6rem 0;
    -webkit-box-shadow: 0px 0px 70px 0px #eaecf0;
  	-moz-box-shadow: 0px 0px 70px 0px #eaecf0;  
    box-shadow: 0px 0px 70px 0px #eaecf0;  */
    .document-row{
        padding: 1rem 0;
        border-bottom: $grey-2 solid 1px;
        margin: 0;
        a{
            border-bottom: none;
            color: $blue-3;
            white-space: pre-line;
        }
        .col-1{
            text-align: right;
        }
        .document-opened{
            .document-icon{
                opacity: 1;
            }
        }
        .document-icon{
            background: url(../images/svg/document.svg) center center no-repeat;
            width: 10px;
            display: inline-block;
            margin-right: 10px;
            opacity: .4;
            &:hover{
                opacity: .8;
                cursor: pointer;
            }
        }
        .document-title{
            display: inline-block;
        }
        .custom-checkbox{
            margin-top: .25rem;
            margin-bottom: 0;
        }
        .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after, .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before{
            margin-top: -5px;
        }
        .custom-checkbox .custom-control-label::before, .custom-checkbox .custom-control-label::after{
            margin-top: -5px;
            border: solid 3px $grey-1;
        }
    }
}

