.login {
    .form-group:nth-child(2) {
        margin-bottom: 3rem;
    }
}

.login,
.register {
    -webkit-box-shadow: 0px 0px 70px 0px #eaecf0;
    -moz-box-shadow: 0px 0px 70px 0px #eaecf0;
    box-shadow: 0px 0px 70px 0px #eaecf0;
    margin: 1rem 0 1rem;


    .btn-primary {
        margin-bottom: 1.5rem;
    }

    .line2 {
        .btn-primary {
            margin-bottom: 0;
        }
    }

    .btn-register,
    .btn-forgot {

        .btn-primary {

            background: transparent;
            padding: 0;
            color: $green-1;
            font-weight: 300;
            border-width: 0;
            border-radius: 0;
            border-bottom: solid 1px white;

            &:hover {
                border-bottom: solid 1px $green-1;
            }

            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled).active {
                background-color: $white;
                box-shadow: none;
            }

            &:not(:disabled):not(.disabled):active:focus,
            &:not(:disabled):not(.disabled).active:focus {
                box-shadow: none;
            }

            a {
                color: $green-1;
            }

        }
    }

    .col-form-label {
        margin-top: 1.5rem;
    }
}


