.application-header{

	padding: 2rem 4rem;
	background-color: $white;
	text-align: right;

	@media screen and (max-width: $break-mobile) {
		padding: 1rem;
	}
	
	.autosave{		
		color: $blue-4;
		@media screen and (max-width: $break-tablet) {
			float: left;
			padding-top: 0;
			margin-top: 5px;
			clear: right;
			min-height: 25px;
		}

		.failed {
			color: $alert-danger-icon;
		}
	}
	.autosave, .text-link{
		display: inline;
		margin-left: 3rem;
		float: right;
		padding: 0;

		@media screen and (max-width: $break-mobile) {
			margin-left: 0;
			padding-top: 0;

			.autosave-time {
				display:none;
			}
		}
	}

	button.text-link {
		background: none;
	}

	.text-link{
		@media screen and (max-width: $break-mobile) {
			clear: right;
			width: auto;
		}
	}
}
ol.breadcrumb{

	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;

	width: 60%;
	float: left;
	background-color: $white;
	padding: 0;

	@media screen and (max-width: $break-mobile) {
		width: 65%;
	}
}

.breadcrumb-item {
	margin-bottom: 0;
}

.breadcrumb-item + .breadcrumb-item {

	padding-left: 1.5rem;
	text-align: left;

	@media screen and (max-width: $break-tablet) {
		padding-left: 1rem;
	}

	@media screen and (max-width: $break-mobile) {
		padding-left: .5rem;
	}

	a{
		text-decoration: none;
		border-bottom: none;
		border-bottom: 1px solid $white;
		color: $blue-4;
		&:hover{
			color: $blue-4;
			border-bottom: 1px solid $blue-4;
		}
	}
	&.active{
		color: $blue-2;
	}
	
	
}

.breadcrumb-item.breadcrumb-home {
	min-width: 24px;
}

.breadcrumb-item:first-child a{
	border-bottom: none;
	img{
		margin-bottom: 6px;
	}
	&:hover img{
		opacity: .7;
	}
}

.breadcrumb-item + .breadcrumb-item::before {
	padding-right: 1.5rem;
	content: url(../images/breadcrumb-chevron.gif);
	@media screen and (max-width: $break-tablet) {
		padding-right: 1rem;
	}
	@media screen and (max-width: $break-mobile) {
		padding-right: .5rem;
	}
}

@include media-breakpoint-down(sm) {
	.breadcrumb-part {
		text-overflow:ellipsis;
		white-space:nowrap;
		overflow: hidden;
	}
}

.breadcrumb-item {
	overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}