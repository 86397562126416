.account{
    -webkit-box-shadow: 0px 0px 70px 0px #eaecf0;
  	-moz-box-shadow: 0px 0px 70px 0px #eaecf0;  
    box-shadow: 0px 0px 70px 0px #eaecf0;
		margin: 1rem 0 6rem 0;
		.col-12, .col-4{
			@media screen and (max-width: $break-mobile) {
				// padding-left: 0;
				// padding-right: 0;
			}
		}
	h2, label[for=verificationCode]{
		margin-top: 1rem;
	}
	/* .btn-primary{
		margin-bottomm: 1rem;
	} */
}