@import '../variables';

.form-check-input[type=checkbox] {
    border-radius: 0;
    width: 1.5rem;
    height: 1.5rem;
    margin-top: 0;

    --bs-border-color: #ebedf1;
    --bs-border-width: 3px;

    &.disabled{
        opacity: .5;
    }
}

.form-check-input:checked[type=checkbox] {
    background-color: $green-1;
    border-color: $green-1;
    --bs-form-check-bg-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2224%22%20viewBox%3D%220%20-960%20960%20960%22%20width%3D%2224%22%3E%3Cpath%20fill%3D%22white%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%20d%3D%22M382-208%20122-468l90-90%20170%20170%20366-366%2090%2090-456%20456Z%22%2F%3E%3C%2Fsvg%3E")
}

.form-check-input:focus {
    box-shadow: none;
    border-color: $grey-6;
}

.custom-checkbox {
    margin-bottom: 1rem;
    margin-top: 0;
    label {
        padding: 0 0 0 1rem;
        line-height: 1.5rem;
        display: inline-block;
    }
}
.custom-control-input:disabled ~ .custom-control-label{
    color: $blue-3;
}
.custom-checkbox.is-invalid, .checkbox-group.is-invalid {
    .invalid-feedback {
        display:block;
    }

    .custom-control-label::after, .custom-control-label::before{
        border: solid 3px $alert-red-border;
    }

}
.checkbox-group .custom-checkbox{
    margin-bottom: 0;
}