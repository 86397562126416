@import '../variables';
/*
FIELD RADIO PICTURE
*/

$radio-picture-item-active-color: map-get($theme-colors, primary);
$radio-picture-item-hover-background: #f6f7f9;
$radio-picture-item-border: #ecedf1;

.picture-radio-container {
    display: flex;
    justify-content: center;
    flex-direction: row; // required so active-indicator don't overlap previous elements
    flex-wrap: wrap; // wraps the boxs ontop of each other in smaller screens
    margin-top: 2rem;

    .picture-radio-item {

        position: relative;
        width: 170px;
        min-width: 170px;
        height: 170px;
        margin: 1rem .5rem;
        background-color: $white;
        border: 4px solid $radio-picture-item-border;
        border-radius: 4px;
        padding: 5px;

        .help-icon-container {
            position: absolute;
            display: block;
            text-align: center;
            left: 0;
            right: 0;
        }

        @media screen and (max-width: $break-mobile) {
            margin: .5rem;
        }

        /*** Hover state ***/
        &:not(.active):not(.disabled):hover {
            cursor: pointer;
            background-color: $radio-picture-item-hover-background;

            .picture {
                color: $grey-1;
            }
        }

        /*** Active state ***/
        &.active {
            border-color: $radio-picture-item-active-color;

            .active-indicator {
                display: inline-block;
                position: absolute;
                top: -16px;
                right: -16px;
                width: 23px;
                height: 23px;
                border-radius: 32px;
                background-color: $radio-picture-item-active-color;
            }

            .active-indicator::after {
                @include material-icon-font;

                font-size: $font-size-base;
                content: 'done';
                display: inline-block;
                margin-left: 4px;
                width: 23px;
                height: 23px;
                color: $white;
            }

            .picture-radio-icon {
                color: $radio-picture-item-active-color;
            }
        }

        /*** Disabled state ***/
        &.disabled {
            cursor: not-allowed;

            .picture {
                color: $radio-picture-item-border;
            }

            background-color: #e9ecef;
        }

        .item-body {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            padding-top: 30px;

            & .picture-radio-icon {
                @include material-icon-font;
                font-size: 60px;
                line-height: 60px;
            }

            & div.picture-radio-text {
                text-align: center;
                line-height: 20px;
            }
        }
    }
}

.picture-radio-container~.invalid-feedback {
    display: block;
}

.picture-radio-key {
    padding: 0 .4rem .1rem .35rem;
    background: $grey-3;
    border-radius: 5px;
    margin-right: .5rem;
    font-size: 14px;
    width: 20px;
    height: 20px;
    line-height: 18px;
    display: inline-block;
}