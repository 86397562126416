@import 'variables';

html, #root, .app{
	height: 100%;
}
body {
    margin: 0;
    padding: 0;
	background: $grey-4;
	height: 100%;
	//TOBIN ADD, with autoscroll to top
	overflow-x: hidden;
}
ol, ul, dl{
	padding-inline-start: inherit;
}
hr {
    margin-top: 2rem;
    margin-bottom: 2rem;
}
li{
	margin-bottom: 10px;
}
 a:hover {
    text-decoration: none;
}

// .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
//     padding-right: 15px;
//     padding-left: 15px;
// }

.padding-bottom{
	margin-bottom: 1.5rem;
}
.padding-top{
	margin-top: 1.5rem;
}
.no-padding{
	padding-right: 0px;
    padding-left: 0px;
}
.less-padding{
	padding-right: 15px;
	padding-left: 15px;
	@media screen and (max-width: $break-mobile) {
		padding-left: 0;
		padding-right: 0;
	}
}
.container {
    max-width: 1380px;
	background: $white;
	min-height: 100%;
	// padding-left: 0;
	// padding-right: 0;
} 

.full-height{
	height: 100%;
}
.page-padding-bottom{
	padding-bottom: 3rem;
}
.bg-white {
  	background-color: $white; 
}
.bg-grey {
  	background-color: $grey-4; 
}
.card{
	border: none;
	margin: 6rem 0;
    -webkit-box-shadow: 0px 0px 70px 0px #eaecf0;
  	-moz-box-shadow: 0px 0px 70px 0px #eaecf0;  
	box-shadow: 0px 0px 70px 0px #eaecf0; 
	@media screen and (max-width: $break-tablet) {
		margin: 0rem 0rem 1rem 0;
	}
}
.table td{
	vertical-align: middle;
}
.invalid-general.invalid-feedback {
	display:block;
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	color: $grey-7 !important;
  }
 ::-moz-placeholder { /* Firefox 19+ */
	color: $grey-7 !important;
}
:-ms-input-placeholder { /* IE 10+ */
	color: $grey-7 !important;
}
:-moz-placeholder { /* Firefox 18- */
	color: $grey-7 !important;
}
input::placeholder { 
	color: $grey-7 !important;
}

.env-footer {
	margin-left: auto;
	margin-right: auto;
	padding: 30px;
	text-align: center;
	color:red;
}