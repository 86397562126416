.statuses-container{
	color: $grey-1;
	padding: 1.5rem 0 9rem 0;
	@media screen and (max-width: $break-mobile) {
		padding: 1rem 0 4rem 0;
	}
    .active {
		color: $green-1;
    }   
}
.application-status{
	padding: 1.25rem 3rem;
	@media screen and (max-width: $break-mobile) {
		padding: 0 1.25rem;
	}
	.status-display{
		width: 14%;
		display: inline-block;
		vertical-align: middle;
		position: absolute;
		@media screen and (max-width: $break-desktop) {
			position: relative;
			display: block;
			margin: 0 auto;
			width: auto;
		}
		&.playlist_add_check{
			left: 0;
			@media screen and (max-width: $break-desktop) {
				left: auto;
			}
		}
		&.stars{
			left: 17%;
			@media screen and (max-width: $break-desktop) {
				left: auto;
			}
		}
		&.assignment_turned_in{
			left: 34%;
			@media screen and (max-width: $break-desktop) {
				left: auto;
			}
		}
		&.supervisor_account{
			left: 51%;
			@media screen and (max-width: $break-desktop) {
				left: auto;
			}
		}
		&.thumb_up{
			left: 68%;
			@media screen and (max-width: $break-desktop) {
				left: auto;
			}
		}
		&.account_balance{
			left: 85%;
			@media screen and (max-width: $break-desktop) {
				left: auto;
			}
		}
		.status-container{
			
			.contents{

				.icon{
					@include material-icon-font;

					font-size:48px;
					text-align: center;
				}
				.text{
					text-align: center;
				}
			}
		}
	}

	.line{
		display:inline-block;
		height: 2px;
		background: $grey-1;
		width: 9%;
		vertical-align: top;
		margin-top: 35px;
		position: absolute;
		@media screen and (max-width: $break-desktop) {
			display:block;
			height: 30px;
			width: 2px;
			margin: 15px auto 5px auto;
			position: relative;
		}
		&.active{
			background: $green-1;
		}
		&:nth-child(2){
			left: 11%;
			@media screen and (max-width: $break-desktop) {
				left: auto;
			}
		}
		&:nth-child(4){
			left: 28%;
			@media screen and (max-width: $break-desktop) {
				left: auto;
			}
		}
		&:nth-child(6){
			left: 45%;
			@media screen and (max-width: $break-desktop) {
				left: auto;
			}
		}
		&:nth-child(8){
			left: 62%;
			@media screen and (max-width: $break-desktop) {
				left: auto;
			}
		}
		&:nth-child(10){
			left: 79%;
			@media screen and (max-width: $break-desktop) {
				left: auto;
			}
		}
	}
}
