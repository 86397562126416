.expert-advice{
	.col-6{
		@media screen and (max-width: $break-tablet) {
			margin: 0 auto 20px auto;
			flex: 0 0 50%;
    		max-width: 50%;
		}
	}
	.expert-advice-img img{
		width: 100%;
	}
	ul{
		padding: 1.25rem;
		margin: 2rem 0;
		background: $grey-3;
		li{
			list-style: none;
			padding-left: 30px;
			background: url(../images/svg/tick-green.svg) 0 6px no-repeat;
    		background-size: 16px 16px;
			margin-bottom: 5px;
		}
	}
}
.reverse-column{
	display: flex;
	@media screen and (max-width: #{$break-tablet - 1}) {
    	flex-direction: column-reverse;
	}
	
}