.sidemenu {
    padding: 2rem 0 0 0;
	height: 100%;
	@media screen and (max-width: $break-mobile) {
		padding: 1rem 0rem 0rem 15px;
	}
	.card-body{
		padding: 0;
	}
}

.journey{
	margin: 4rem 0rem 0rem;
	@media screen and (max-width: $break-tablet) {
		margin: 2rem 0rem 0rem;
	}
	& .text{
		font-weight: 700;
		color: $grey-1;
		padding-left: 20px;	
		@media screen and (max-width: $break-desktop) {
			font-size: 80%;
		}
		@media screen and (max-width: $break-tablet) {
			font-size: 100%;
		}	
	}	
}

.inactive{
	.journey-step{
		.content{
			margin-top: -10px;
			.dot {
    			background-color: $grey-1;
				width: 10px;
    			height: 10px;
			}
			.text{
				color: $grey-1;
			}
		}
	}
	&.available{
		.journey-step{
			.content{
				.dot {
    			background-color: $green-1;
			}
				.text{
					color: $green-1;
					&:hover{
						color: $green-2;
					}
				}				
			}
		}
	}
	
}

.active{
	.journey-step{
		.content{
			margin-top: -10px;
			.dot {
    			background-color: $green-1;
				width: 10px;
    			height: 10px;
				.outer{
					background-color: $green-4;
					width: 20px;
					height: 20px;
					margin-left: 5px;
					margin-top: 5px;
				}
			}
			.text{
				color: $green-1;
			}
		}
	}
	&.available{
		.journey-step{
			.content{
				.dot {
					.outer{
						background-color: $green-4;
						width: 20px;
						height: 20px;
						margin-left: 5px;
						margin-top: 5px;
					}
				}
				.text{
					color: $green-1;
									
				}				
			}
		}
	}
}

.inactive, .active{
	.journey-step{
		.line{
			margin-left: 4px;
			margin-top: -4px;
		}
	}
	&.available{
		.journey-step{		
				.line{
					background-color: $green-1;
				}
		}
	}
}

.completed{
	.journey-step{
		.line{
			margin-left: 4px;
			margin-top: -4px;
		}
		.content{
			.dot {
    			background-color: $blue-1!important;
			}
			.text{
				color: $blue-1;
				&:hover{
					color: $blue-2;
				}
			}
		}
	}
}

.sidemenu{
	.card-body{
		.text-link{
			position: absolute;
			bottom: 30px;
			color: $blue-3;
			background: url(../images/icons/sidemenu-contact-icon.gif) center left no-repeat;
			padding-left: 20px;
			border-bottom: none;
			@media screen and (max-width: $break-tablet) {
				position: relative;
				bottom: 0px;
				margin-top: 20px;
			}
			@media screen and (max-width: $break-desktop) {
				display: none;				
			}

			@media screen and (max-height: 570px) {
				display: none;
			}
		}
	}
}
