
.register-page {

    .custom-checkbox {
        margin-bottom: 0rem;
        margin-top: 1rem;
    }

    .head-title {
        text-transform: uppercase;
    }

    .block-title {
        font-weight: bold;
        color: $green-1;
    }

    .register-left-block {

        ul.do-list {

            list-style-type: none;
            padding: 0;

            & li:before {
                @include material-icon-font;
                content: 'check';
                color: $green-1;
                vertical-align: middle;
                font-weight: bold;
                padding-right: 8px;
            }
        }

    }

    .main-block {
        padding-bottom: 24px;
    }

    .testimonials {
        margin-bottom: 36px;
    }

}

