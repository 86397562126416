
table {

    &.table-fixed {
        table-layout: fixed;
    }

    &.table-vertical-align-top {
        & td {
            vertical-align: top;
        }
    }

}