.label-small,
.label-description,
.label-large {
  .help-icon-container {
    display: inline-block;
    position:relative;
    top: -3px;
    margin-left: 12px;
  }
}

legend.warning{
  color: $alert-warning-icon;
}