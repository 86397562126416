label[for=GSNameCasual],
label[for=NewApplicantName],
.form-group .label,
.form-group label,
.col-form-label {
    font-size: 28px;
    color: $blue-1;
	margin-bottom: 1rem;
	@media screen and (max-width: $break-mobile) {
		font-size: 20px;
		line-height: 24px;
	}
}

.col-form-label{
	margin-bottom: .5rem;
}

.label-small,
.form-group label.label-small,
.form-group .label.label-small {
	font-size: 18px;
	font-weight: 400;
	line-height: 24px;
}

.label-description,
.form-group label.label-description {
	font-size: 16px;
	font-weight: 400;
	line-height: 18px;
	margin-bottom: 2rem;
}

.label-large,
.form-group label.label-large {
	display: block;
	width: 100%;
	max-width: 100%;
	padding: 0;
	margin-bottom: 1rem;
	font-size: 28px;
	line-height: inherit;
	color: #002b5d;
	white-space: normal;

	@media screen and (max-width: $break-mobile) {
		font-size: 20px;
		line-height: 24px;
	}
}

.label-error {
	color: $alert-red;
	font-size: 18px;
	font-weight: 400;
	line-height: 18px;
	margin-bottom: 2rem;
}

.small-label label{
	font-size: 20px;
}
.form-group{
	label.form-check-label{
		font-weight: 400;
		color: #73829A;
		font-size: 16px;
	}
	.col-form-note{
		font-size: 16px;
		@media screen and (max-width: $break-mobile) {
			font-size: 14px;
		}
	}
}
.col-form-description {
	font-size: 16px;
}

