// Used as BUTTON or LINK styling
a,
.text-link {
    display: inline-block;
    border: none;
    border-bottom: solid 1px transparent;
    text-decoration: none;
    color: $green-1;
    cursor: pointer;

    &:hover {
        border-bottom: solid 1px $green-1;
        color: $green-1;
    }

    .btn-left-material-icon,
    .btn-right-material-icon {
        @include material-icon-font;

        vertical-align: middle;
    }
    &.no-text {
        border-bottom: solid 1px transparent;
        padding: 0;
        margin: 0;
    }
}

button.text-link {
    padding: 0
}

button.nav-link.text-link {
    padding: 0.5rem 1rem;
}

.btn {

    &.spaced {
        /** default button positioning so that they stack well both horizontally and vertically on mobile */
        margin-bottom: 1rem;
        margin-right: 20px;
    }

    /** nowrap so that button icons don't go to second line */
    white-space: nowrap;
    font-weight: 700;

    .btn-left-material-icon,
    .btn-right-material-icon {
        @include material-icon-font;

        vertical-align: bottom;
        line-height: 21px;
    }
    .btn-left-material-icon {
        margin-right: 1rem;
    }
    .btn-right-material-icon {
        margin-left: 1rem;
    }
}


.btn-primary {
    // Bootstrap5 automatic colors `are different from bootstrap4, override bootstrap5 behaviour
    color: white;

    &:focus {
        color: white;
    }

    &:hover {
        color: white;
    }

    padding: .75rem 2rem;
    border: 3px solid $green-1;
    /* @media screen and (max-width: $break-mobile) {
        padding: .4rem .9rem .5rem .8rem;
        border: 2px solid $green-1;
    } */
    &.icon {
        padding-right: 60px;
        background: $green-1 url(../images/chevron.png) 87% 50% no-repeat;
    }

    &.next {
        padding-right: 60px;
        background: $green-1 url(../images/chevron.png) 77% 50% no-repeat;
        margin: 0 0.75rem;
        @media screen and (max-width: $break-mobile) {
            margin: 0 0.5rem;
            padding-right: 40px;
        }
    }

    &:hover {
        background-color: $green-2;
        border-color: $green-2;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        background-color: $green-2;
        box-shadow: 0px 0px 25px 1px $green-2;
        border-color: $green-2;
    }

    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0px 0px 25px 1px $green-2;
    }

    &.disabled {
        background-color: $grey-1;
        border-color: $grey-1;
        box-shadow: none !important;
        color: #fff;
    }
}

.btn-secondary {
    padding: .75rem 2rem;
    background-color: $white;
    border: 3px solid $grey-1;
    color: $blue-2;

    &:focus {
        border: 3px solid $grey-1;
        color: $blue-2;
    }

    &.plus {
        padding-right: 60px;
        background: $white url(../images/plus_secondary.png) 87% 50% no-repeat;
    }
    &.previous {
        padding-left: 60px;
        background: $white url(../images/chevron-back.png) 23% 50% no-repeat;
        margin: 0 0.75rem;
        @media screen and (max-width: $break-mobile) {
            margin: 0 0.5rem;
            padding-left: 40px;
        }
    }
    &:hover {
        background-color: $grey-3;
        color: $blue-2;
        border: 3px solid $grey-1;
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        background-color: $white;
        box-shadow: 0px 0px 25px 1px $grey-1;
        border: 3px solid $grey-1;
        color: $blue-2;
    }
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0px 0px 25px 1px $grey-1;
    }
}

.borrow-estimate {
    .btn-primary {
        padding-right: 60px;
        background: $green-1 url(../images/chevron.png) 77% 50% no-repeat;
        margin: 0 0.75rem;
        &:hover {
            background-color: $green-2;
            border-color: $green-2;
        }
    }
    .btn-secondary {
        padding-left: 60px;
        background: $white url(../images/chevron-back.png) 30px 50% no-repeat;
        margin: 0 0.75rem;
        &:hover {
            background-color: $grey-3;
            color: $blue-2;
            border: 3px solid $grey-1;
        }
    }
}

.control-buttons {
    margin-top: 4rem;
    @media screen and (max-width: $break-mobile) {
        margin-top: 3rem;

        /* .button-text {
            display:none;
        } */
    }
}

.simple-form-buttons {
    margin-top: 3rem;
}

.button-row {
    .btn {
        margin-left: 25px;
        @media screen and (max-width: $break-mobile) {
			margin-left: 0;
		}
    }
    .btn:first-child {
        margin-left: 0px;
        margin-bottom: 1rem;
    }
}

//.col-5{
//	text-align: right;
//}
.btn-primary {
    &.group-addable-edit,
    &.group-addable-delete,
    &.group-addable-save,
    &.group-addable-add,
    &.group-addable-cancel,
    &.applicant-add-remove,
    &.applicant-add-delete,
    &.applicant-add-add,
    &.btn-simple,
    &.applicant-add-save {

		&::before {
            @include material-icon-font;
        }
        
        display: inline-flex;

        border: none;
        background-color: transparent;
        /* padding-left: 38px; */
        padding-left: 0px;
        padding-right: 0; //margin-left: 1.5rem;

        color: $green-1;
        font-weight: normal;

        & span {
            margin-left: 4px;
        }

        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active:focus,
        &:not(:disabled):not(.disabled).active:focus,
        &:focus {
            box-shadow: none !important;
            background-color: transparent;
            border: none;
            color: $green-1;
        }


    }
    &.group-addable-edit {

        margin-right: 20px;

        &::before {
            content: 'edit';
        }
        &.disabled {
            color: $grey-1;
            span {
                &:hover {
                    border-bottom: none;
                }
            }
        }
        span {
            border-bottom: 1px solid $white;
            &:hover {
                border-bottom: 1px solid $green-1;
            }
        }
    }
    &.group-addable-delete,
    &.applicant-add-delete,
    &.applicant-add-remove {

        align-self: center;

        &::before {
            content: 'delete';
        }
        &.disabled {
            color: $grey-1;
            span {
                &:hover {
                    border-bottom: none;
                }
            }
        }
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active:focus,
        &:not(:disabled):not(.disabled).active:focus {
            box-shadow: none;
            background-color: transparent;
            border: none;
            color: $green-1;
        }
        span {
            border-bottom: 1px solid $white;
            &:hover {
                border-bottom: 1px solid $green-1;
            }
        }
    }
    &.group-addable-cancel {
        background: url(../images/svg/cross.svg) center left no-repeat;
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active:focus,
        &:not(:disabled):not(.disabled).active:focus {
            box-shadow: none;
            background-color: transparent;
            border: none;
            color: $green-1;
        }
        span {
            border-bottom: 1px solid $white;
            &:hover {
                border-bottom: 1px solid $green-1;
            }
        }
    }

    &.applicant-add-save,
    &.group-addable-save {
        &::before {
            content: 'save';
        }
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active:focus,
        &:not(:disabled):not(.disabled).active:focus {
            box-shadow: none;
            background-color: transparent;
            border: none;
            color: $green-1;
        }
        span {
            border-bottom: 1px solid $white;
            &:hover {
                border-bottom: 1px solid $green-1;
            }
        }
    }

    &.group-addable-add,
    &.applicant-add-add/*, &.group-addable-save*/ {

		&::before {
            content: 'add';
        }

		border: none;
        padding: 0 0 0 0;
        margin-top: 1rem;
        color: $green-1;

		span {
            border-bottom: 1px solid $white;
            &:hover {
                border-bottom: 1px solid $green-1;
            }
        }

		&:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active:focus,
        &:not(:disabled):not(.disabled).active:focus {
            box-shadow: none;
            background-color: transparent;
            border: none;
            color: $blue-4;
        }
    }

    /* @media screen and (max-width: $break-mobile) {
        margin-bottom: 1rem;
    } */
}


.btn-simple {
    span:hover {
        border-bottom: 1px solid $green-1;
    }
}

.btn-copy {
    &::before {
        content: 'file_copy';
    }
}