.journey-step {
    $dotSize: 20px;
    $outerDotRelSize:2;
    .content {
        >div {
            display: inline-block;
        }
        height: $dotSize;
        .dot {
            height: $dotSize;
            width: $dotSize;
            border-radius: 50%;
            position: relative;
            .outer {
                position: absolute;
                height: $outerDotRelSize * $dotSize;
                width: $outerDotRelSize * $dotSize;
                left: - 0.5 *$dotSize; // I can't woirk this out :(
                top: - calc($dotSize / $outerDotRelSize);
                border-radius: 50%;
                background-color: $green-3;
                opacity: 0.5;
                display: none;
                .active & {
                    display: initial;
                }
            }
            background-color: $grey-1;
            .active & {
                background-color: $green-1;
                cursor: auto;
            }
            .completed & {
                background-color: $blue-1;
            }
        }
    }
    .line {
        height: 50px;
        width: 2px;
        background-color: $grey-1;
        @media screen and (max-width: $break-tablet) {
            height: 35px;
        }
        .active & {
            background-color: $green-1;
        }
        .completed & {
            background-color: $blue-1;
        }
    }

    .available & {
        .dot,
        .text {
            cursor: pointer;
        }
    }
}