.dropdown-toggle.btn-primary {
    &,
    :focus {
        .show>& {
            color: $blue-2;
            background-color: $white;
            border: 3px solid $grey-1;
            box-shadow: 0px 0px 25px 1px $white-2;
        }
    }
}

/*
The 1 above is the same as these 2 below.

.show>.btn-primary.dropdown-toggle {
    
    color: $blue-2;
    background-color: $white;
    border: 3px solid $grey-1;
  
    box-shadow: 0px 0px 25px 1px $white-2;
}

.show>.btn-primary.dropdown-toggle:focus {
    color: $blue-2;
    background-color: $white;
    border: 3px solid $grey-1;
  
    box-shadow: 0px 0px 25px 1px $white-2;
}
*/

select.form-select{
    background:  $white url(../images/dropdown-arrowdown.png) 96% center no-repeat;
    font-family: 'Niramit', sans-serif;
    font-weight: 400;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 3.2rem;
    color: $blue-3;
    border: 3px solid $grey-6;
     -webkit-appearance: none;  /*Removes default chrome and safari style*/
     -moz-appearance: none;  /*Removes default style Firefox*/
    text-indent: 0.01px;
    text-overflow: "";
    padding: 0 1.5rem;
    @media screen and (max-width: $break-mobile) {
        -moz-white-space: pre-wrap;
        -o-white-space: pre-wrap;
        white-space: pre-wrap;
        padding: 0 3rem 0 1.5rem;
        line-height: 1.4rem;
    }
    &:hover{
        border: 3px solid $grey-6;
    }
}
select.form-select#security_question{
    margin-bottom: 1rem;
}
// Triple .form-select to override bootstrap5 selectors
select.form-select.form-select.form-select.is-valid,
select.form-select.form-select.form-select.is-valid:focus {
    border-color: $green-5;
    box-shadow: none;
    background-color: $white;
    background-image: url(../images/svg/tick-green.svg);
    background-size: 18px;
    background-repeat: no-repeat;
    background-position-y: 50%;
    background-position: right 20px center;
}
select.form-select.form-select.form-select.is-invalid,
select.form-select.form-select.form-select.is-invalid:focus {
    background-color: $white;
    background-image: url(../images/svg/invalid-cross.svg);
    background-size: 24px;
    background-repeat: no-repeat;
    background-position-y: 50%;
    background-position: right 15px center;
}

select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}
select > option {
    font-size: inherit;
}

::-moz-selection{
    background:$blue-5 !important;
    color:$blue-3 !important;
}
::selection {
    background:$blue-5 !important;
    color:$blue-3 !important;
}
::-webkit-selection{
    background:$blue-5 !important;
    color:$blue-3 !important;
}
::-ms-selection{
    background:$blue-5 !important;
    color:$blue-3 !important;
}
::-o-selection {
    background:$blue-5 !important;
    color:$blue-3 !important;
}