.application-list {
    -webkit-box-shadow: 0px 0px 70px 0px #eaecf0;
  	-moz-box-shadow: 0px 0px 70px 0px #eaecf0;  
    box-shadow: 0px 0px 70px 0px #eaecf0;
    margin: 1rem 0 6rem 0;
    table {

        > :not(:first-child) {
            border-top: 4px solid $grey-1;

            @media screen and (max-width: $break-desktop) {
                border-top: none;
            }
        }

        > tbody > :last-child > * {
            border-bottom:none;
        }

        margin-bottom: 0;
        @media screen and (max-width: $break-desktop) {
            text-align: center;
        }
        thead tr{
            @media screen and (max-width: $break-desktop) {
                display: none;
            }
        }
        tbody tr{
            @media screen and (max-width: $break-desktop) {
                width: 46%;
                display: inline-block;
                margin: 2%;
                border: 2px solid $grey-1;
            }
            @media screen and (max-width: $break-tablet) {
                width: 96%;
            }
        }
        tbody td:nth-child(2), tbody td:nth-child(3){
            @media screen and (max-width: $break-desktop) {
                display: none;
            }
        }
        tbody tr:last-child{
            @media screen and (max-width: $break-desktop) {
                display: inline-block;
                margin-top: 5px;
                border: none;
            }
        }
        tbody td {
            --bs-border-width: 0;

            @media screen and (max-width: $break-desktop) {
                display: block;
                border-top: none;
                padding: 0.5rem 0.75rem;
            } 
        }
    }
    .icon{
        .dashboard-bin{
            background: url(../images/svg/delete.svg) center center no-repeat;
            display: block;
            height: 25px;
            &:hover{
                opacity: .8;
                cursor: pointer;
            }
        }
        &.disabled{
            .dashboard-bin{
                opacity: .3;
                &:hover{
                    cursor: auto;
                }
            }
            
        }
    }
    .oos-title{
        @media screen and (max-width: $break-mobile) {
            text-align: center;
        }
    }
    .col-12{
        padding-left: 0;
        padding-right: 0;
    }
}

.applications-short-date {
    display:none;
}

@media screen and (max-width: $break-desktop) {
    .applications-short-date {
        display:inline-block;
    }
}
